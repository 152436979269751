.container {
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 32px;
}

.mailField {
    text-align: left;
    max-width: 308px;
}

.internalDriverContainer,
.externalDriverContainer {
    display: flex;
    flex-direction: column;
}


