
.iconContainer {
    height:65px;
    width: 73px;
    margin: 34px auto;
}

.contentContainer {
    text-align: center;
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-2);
    color: var(--primary-black);
}

.errorIcon {
    transition-delay: .5s;
}

.errorIconAnimation {
    position: relative;
    z-index: 0;
    transform: scale(1.2);
    transition: .7s;
    transition-delay: .1s;
}

.dialogButton {
    margin: 26px auto;
}

.dialogActions {
    margin: 24px auto;
}


