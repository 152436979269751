
.title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 25px;
    background-color: var(--background);
}

.title h1 {
    line-height: 133px;
    white-space: nowrap;
}

.clientSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 25px;
}

.sectionColumn {
    margin: 0 60px 20px 0;
}

.dropdownSection {
    flex-basis: 610px;
}

.divider {
    height: 25px;
    background-color: var(--background);
}

@media (max-width: 992px) {
    .title {
        justify-content: center;
    }

    .title h1 {
        line-height: inherit;
        margin-top: 20px;
    }
}

.submitButton {
    margin: 0;
    top: auto;
    left: auto;
    bottom: 32px;
    right: 32px;
    position: fixed;
    width: 123px;
}
