.button {
    font-family: var(--avenir-next-demi);
    background-color: #FFF;
    color: var(--primary-red);
    border: 1px solid var(--primary-red);
    border-radius: 3px;
    min-width: 170px;
    height: 43px;
    font-size: var(--button-fs);
    transition: .3s
}

.button:hover {
    background-color: var(--primary-red);
    color: #FFF;
    cursor: pointer;
}

.buttonIcon {
    vertical-align: 2px;
    margin-right: 7px;
}

.buttonIcon svg path {
    transition: .3s
}

.button:hover .buttonIcon svg path {
    fill: #FFF;
    stroke: #FFF
}

.disabledButton{
    font-family: var(--avenir-next-demi);
    background-color: #FFF;
    color: #909090;
    border: 1px solid #909090;
    border-radius: 3px;
    min-width: 170px;
    height: 43px;
    font-size: var(--button-fs);
    transition: .3s
}

.withIcon {
    padding-right: 13px;
    line-height: inherit;
}

.withoutIcon {
    padding-right: 6px;
    line-height: 43px;
}