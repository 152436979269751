.rdrDayToday .rdrDayNumber span:after{
    background: #ff0000;
}
.rdrSelected, .rdrInRange {
    background: rgba(109,109,109,0.18);

}
.rdrStartEdge, .rdrEndEdge{
    background: #FF0000;
}

.rdrStartEdge{
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    left: 2px;
}

.rdrEndEdge{
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    right: 2px;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    left: 2px;
}

.rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    right: 2px;
}

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    border-right-width: 1px;
    right: 0;
}

.rdrDateDisplayItemActive{
    border-color: #FF0000;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
    color: rgb(0, 0, 0);
}

 .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
    color: rgb(255, 255, 255);
}


.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview {
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    border-left-width: 1px;
    left: 0;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border-right-width: 1px;
    right: 0;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    bottom: 3px;
    pointer-events: none;
    border: 0 solid #FF0000;
    z-index: 1;
}

.rdrDayStartPreview {
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 0.5em;;
    border-bottom-left-radius: 0.5em;;
    left: 0;
}

.rdrDayInPreview{
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.rdrDayEndPreview {
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    right: 0;
}
