@font-face {
    font-family: 'AvenirNextLTPro-Demi';
    font-style: normal;
    font-weight: 900;
    src: url(assets/fonts/avenir-next/AvenirNextLTPro-Demi.woff) format('woff')
}

@font-face {
    font-family: 'AvenirNextMedium';
    font-style: normal;
    font-weight: 500;
    src: url(assets/fonts/avenir-next/AvenirNextMedium.ttf) format('opentype')
}

@font-face {
    font-family: 'AvenirNextLTPro-Regular';
    font-style: normal;
    font-weight: 500;
    src: url(assets/fonts/avenir-next/AvenirNextLTPro-Regular.woff) format('woff')
}

