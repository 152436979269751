.credentialsContainer {
    display: flex;
    flex-direction: column;
    margin: 0 17px;
    text-align: left;
}

.credentialsContainer h2 {
    align-self: flex-start;
}

@media (max-width: 400px) {
    .credentialsContainer {
        align-items: center;
    }
}
