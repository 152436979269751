.menuIconContainer {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 5px;
    cursor: pointer;
    transition: .3s;
    text-align: center;
    border-radius: 50%;
}

.menuIconContainer:hover {
    background-color: #FFE2E28F;
}

.menuIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}