.loginWrapper {
    display: flex;
    margin: 68px 0;
}

.loginFormContainer {
    display: flex;
    flex-direction: column;
}

.loginContainer h1 {
    margin: 101px 0 51px;
    text-align: left;
}

.loginContainer {
    display: flex;
    flex-direction: column;
}

.truckContainer {
    flex-grow: 1;
    position: relative;
}

.splashAsset {
    position: absolute;
    right: 0;
    transform: translateY(-70px);
    width: 100%;
}

.truckAsset {
    position: absolute;
    padding: 0 64px;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.loginFormWidth {
    width: 363px;
}

@media (max-width: 1224px) {
    .loginWrapper {
        margin-left: 90px;
    }

    .truckContainer {
        display: none;
    }

    .loginContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (max-width: 800px) {
    .loginContainer {
        transform: translate(-50%, -50%) scale(0.75);
    }
}

@media (max-width: 800px) and (orientation: landscape) {
    .loginContainer h1 {
        display: none;
    }

    .loginFormContainer {
        margin-top: 30px;
    }
}
