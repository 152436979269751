.container {
    display: flex;
    flex-direction: column;
    margin: 15px 17px 15px;
    text-align: left;
}

.container h2 {
    margin-bottom: 32px;
}

.containerRow {
    display: flex;
    max-height: 100%;
    flex-wrap: wrap;
}

.buttonContainer {
    text-align: left;
}
