#header, #container {
    box-sizing: border-box;
}

.App {
    font-family: var(--avenir-next-regular);
    text-align: center;
    background: #FFF;
}

/*GLOBAL STYLES OVERRIDE FOR MUI COMPONENTS*/

.MuiFilledInput-root {
    font-family: var(--avenir-next-regular);
    border: 1px solid #E2E2E1;
    border-radius: 3px;
    background-color: #FFF;
}

.MuiFilledInput-root:hover {
    background-color: var(--hover);
}

.MuiFilledInput-root.Mui-focused {
    border: 1px solid var(--selected-input);
    background-color: #FFF;
}

.MuiFilledInput-root.Mui-error {
    border: 1px solid var(--validation);
    background-color: var(--validation-background);
}

.MuiInputBase-root.Mui-error {
    color: var(--validation);
    background-color: var(--validation-background);
}

.MuiFormLabel-root {
    font-family: var(--avenir-next-regular);
    color: var(--caption);
}

.MuiFormLabel-root.Mui-error {
    color: var(--validation);
}

.MuiInputBase-root {
    font-family: var(--avenir-next-regular);
}

.MuiInputBase-input.MuiOutlinedInput-input::placeholder {
    opacity: 1;
    color: var(--caption);
}

.MuiInputBase-input.MuiOutlinedInput-input:hover,
.MuiOutlinedInput-root:hover {
    cursor: pointer;
    background-color: var(--hover);
}

.MuiOutlinedInput-notchedOutline {
    border-color: #e2e2e1;
}

.MuiOutlinedInput-root:hover > .MuiOutlinedInput-notchedOutline {
    border-color: #e2e2e1;
}

.MuiInputBase-root.Mui-error > .MuiInputBase-input.MuiOutlinedInput-input::placeholder {
    color: var(--validation);
}

.MuiFormControlLabel-label {
    font-family: var(--avenir-next-regular);
}

.MuiTypography-body1 {
    font-family: var(--avenir-next-demi)
}

.MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
}

.MuiFormLabel-root.Mui-disabled,
.MuiInputBase-input.Mui-disabled,
.MuiFormControlLabel-label.Mui-disabled {
    color: var(--primary-black)
}

.MuiTableCell-root.MuiTableCell-head {
    font-family: var(--avenir-next-medium);
    font-size: 13px;
    font-weight: 500
}

.MuiInputBase-root.MuiOutlinedInput-adornedStart {
    padding-left: 2px;
}

.MuiMenu-list {
    padding: 0;
}

.clearDisabled > div.MuiFilledInput-root.Mui-disabled {
    background-color: #FFF;
}

.backgroundColorValidation {
    background-color: var(--validation-background);
}

@media (max-width: 1224px) {
    .navigationWrapper {
        width: 0;
    }
}
