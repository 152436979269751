.container{
  display: flex;
  flex-direction: column;
}

.containerRow {
    align-self: center;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.block{
  width: 100%;
}

.select:first-child {
  margin-right: 40px;
}

@media (max-width: 992px) {
  .containerRow {
    padding-top: 0;
    flex-direction: column;
  }

  .select:first-child {
    margin-right: 0;
  }
}

/*@media (max-width:740px) {*/
/*  .select:first-child {*/
/*    margin-right: 0;*/
/*  }*/
/*}*/
