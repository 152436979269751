.droppable {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 300px;
}

.openButton {
    width: 40px;
    height: 40px;
    transition: .3s;
    border: none;
    border-radius: 50%;
    background-color: #FFF;
}

.openButton:hover {
    background-color: var(--hover);
}

.openButton:focus {
    outline: none;
}

.sortedColumnIsHiddenIndicator {
    color: var(--primary-red);
}

.draggable {
    flex: 1 1;
    width: 100%;
    padding: 0 16px;
    user-select: none;
    color: #000;
    border-bottom: 1px solid #E2E2EA;
    background-color: #FFF;
}

.draggable > div {
    height: 50px;
    display: flex;
}

.draggable > div > div {
    align-self: center;
}

.isDragging {
    border: none;
    background-color: var(--hover);
}

.MuiMenu-list {
    padding-bottom: 0;
}

.label {
    margin-right: 8px;
}
