.icon {
    width: 56px;
    height: 50px;
    fill: #CBCBCB;
    cursor: pointer;
    padding: 11px;
    border-radius: 7px;
    margin-right: 17px;
    transition: .3s;
    color: var(--primary-black);
}

.navigationButton {
    align-items: center;
    display: flex;
}

.navigationButton span {
    font-family: var(--avenir-next-demi);
}

.buttonWrapper:hover {
    transition: 0.5s;
    color: var(--primary-red);
}

.buttonWrapper:hover .icon {
    fill: var(--primary-red) !important;
    background-color: var(--hover);
}

.buttonWrapper {
    cursor: pointer;
    padding-left: 17px;
}