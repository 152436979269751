.accessIcon {
    height: 18px;
    width: 18px;
    margin-top: auto;
    margin-bottom: auto;
}

.denied {
    fill: lightgrey;
}

.accepted {
    fill: #21bb3a;
}

.cursorPointer{
    cursor: pointer;
}