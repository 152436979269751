.progressBarWindow {
    width: 87vw;
    overflow-x: auto;
    margin: 50px auto;
}

.progressBarWrapper {
    display: flex;
    margin-bottom: 10px;
}

.progressLineWrapper {
    display: flex;
}

.bubbleFull {
    height: 41px;
    width: 41px;
    border-radius: 50%;
    line-height: 45px;
    font-family: var(--avenir-next-demi);
    font-size: var(--heading-2);
    background-color: var(--hover);
}

.bubbleHalf {
    height: 41px;
    width: 41px;
    border-radius: 50%;
    line-height: 45px;
    font-family: var(--avenir-next-demi);
    font-size: var(--heading-2);
    background: linear-gradient(to right, var(--hover) 50%, var(--background) 50%);
}

.bubbleEmpty {
    height: 41px;
    width: 41px;
    border-radius: 50%;
    line-height: 45px;
    font-family: var(--avenir-next-demi);
    font-size: var(--heading-2);
    background-color: var(--background);
}

.joiningLine {
    min-width: 112px;
    height: 2px;
    background-color: var(--background);
    align-self: center;
}

.joiningLineFull {
    width: 112px;
    height: 2px;
    background-color: var(--hover);
    align-self: center;
}

.progressBarWrapper > div:first-child > .progressLineWrapper > div:first-child {
    display: none;
}

.progressBarWrapper > div:first-child > .progressLineWrapper {
    justify-content: flex-end;
}

.statuses {
    margin-top: 10px;
    transform: translateX(38%)
}

.progressBarWrapper > div:first-child > .statuses {
    transform: translateX(30%)
}

.notDoneCaption {
    font-family: var(--avenir-next-medium);
    font-size: var(--body-1-fs);
    color: rgba(0, 0, 0, 0.47)
}

.doneCaption {
    font-family: var(--avenir-next-medium);
    font-size: var(--body-1-fs)
}


