.historyItems {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

.action:first-child {
    background-color: var(--extra);
    font-family: var(--avenir-next-demi);
}

.emptyHistory:first-child {
    background-color: var(--validation-background);
    font-family: var(--avenir-next-demi);
}

.historyItem {
    display: flex;
    justify-content: flex-start;
    line-height: 48px;
    padding-left: 20px;
    font-size: var(--heading-3);
    font-family: var(--avenir-next-medium);
}

.historyItemCell {
    margin-right: 30px;
}

.historyItemTypeCell {
    min-width: 300px;
    text-align: left;
}

.expanderBox {
    text-align: left;
    margin: 10px 0 0 20px;
}

.expanderBox:hover {
    cursor: pointer;
}

.expandText {
    color: var(--primary-red)
}

.expandIconCollapsed {
    transition: .3s;
}

.expandIconExpanded {
    transition: .3s;
    transform: rotate(180deg);
}

.clickable {
    font-family: var(--avenir-next-regular);
    text-decoration: underline;
    white-space: nowrap;
    cursor: pointer;
    transition: .3s;
}

.clickable:hover {
    color: var(--primary-red)
}

@media (max-width: 710px) {
    .historyItems {
        overflow-x: auto;
        width: 100%;
    }

    .historyItem {
        width: 700px;
    }
}

.errorIcon {
    height: 19px;
    width: 19px;
    fill: var(--primary-red);
}