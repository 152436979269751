.container {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='red' stroke-width='2' stroke-dasharray='16%2c 10' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin-top: 33px;
    height: 56px;
}

.container:focus {
    outline: none;
}

.container > div {
    line-height: 38px;
    font-family: var(--avenir-next-medium);
    font-size: 15px;
}

.fileChipRow {
    margin: 25px auto;
}

.fileChipRow ul {
    padding: 0;
    list-style: none;
    display: grid;
    column-gap: 25px;
    row-gap: 15px;
}

@media (max-width: 800px) {
    .fileChipRow ul {
        grid-template-columns: repeat(1, 1fr);
    }
}
