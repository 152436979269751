:root {
    /*Font sizes*/
    --heading-1: 30px;
    --heading-2: 20px;
    --heading-3: 17px;
    --button-fs: 17px;
    --body-1-fs: 15px;
    --body-2-fs: 13px;
    /*Font types*/
    --avenir-next-demi: 'AvenirNextLTPro-Demi' , sans-serif;
    --avenir-next-medium:'AvenirNextMedium' , sans-serif;
    --avenir-next-regular:'AvenirNextLTPro-Regular' , sans-serif;
}

h1 {
    font-family: var(--avenir-next-demi), serif;
    font-size: var(--heading-1) !important;
}

h2 {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-2) !important;
}

h3 {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-3) !important;
}

button {
    font-size: var(--button-fs);
}

