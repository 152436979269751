.containerColumn {
    display: flex;
    flex-direction: column;
    max-width: 310px;
    flex-wrap: nowrap;
    margin-right: 60px;
}

.containerRow {
    display: flex;
    justify-content: space-between;
}

.dateTimeFieldsContainer {
    display: flex;
    justify-content: space-between;
}

.optionalFeaturesContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
}

.optionalFeaturesContainer h2 {
    height: 42px;
    margin-bottom: 0;
}

.optionalFeatureCheckBoxContainer {
    height: 58px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttonContainer {
    text-align: left;
}

.cargoComponentWrapper {
    margin-bottom: 25px;
}

.cargoComponentWrapper > div:nth-last-child(2) > div:last-child {
    margin-bottom: 0;
}

.countrySelect {
    width: 159px;
}

.px4 {
    padding-left: 4px;
    padding-right: 4px;
}

.fieldHorizontalSeparator {
    width: 8px;
}

.cargoTimeField {
    width: 112px;
}

.cargoZipCodeField {
    width: 148px;
}

.addDeliveryPointButton {
    min-width: 191px;
}

.cargoQuantityField {
    width: 159px;
}

.cargoWeightField {
    width: 146px;
}