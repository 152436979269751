.container {
    display: flex;
    flex-direction: column;
}

.containerRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 25px;
    margin-bottom: 50px;
}

.cargoTitle {
    display: flex;
}

.button {
    padding: 6px;
    border: none;
    border-radius: 50%;
    background: none;
}

.button:focus {
    outline: none;
}

.button:hover {
    background-color: var(--hover);
}

.line {
    flex-grow: 1;
    height: 41px;
    background: repeating-linear-gradient(to right,
    #e8e8f6 0,
    #e8e8f6 20px, transparent 20px, transparent 33px) center no-repeat;
    background-size: 100% 2px;
}

.titleContainer {
    display: flex;
}

.indexBox {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-3);
    line-height: 41px;
    min-width: 41px;
    height: 41px;
    border-radius: 3px;
    background-color: var(--background);
}

.titleBox {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-3);
    line-height: 41px;
    min-width: 265px;
    height: 41px;
    margin-left: 6px;
    padding-left: 15px;
    text-align: left;
    border-radius: 3px;
    background-color: var(--background);
}

.utilityButtonsContainer {
    white-space: nowrap;
}

@media (max-width: 430px) {
    .titleBox {
        min-width: 200px;
    }
}
