:root {
    --transition-time: .5s;
}

.logoBoxClosed {
    display: flex;
    flex-direction: row;
    transition: var(--transition-time);
    transform: translate(-45px);
}

.logoBoxOpen {
    display: flex;
    flex-direction: row;
    transition: var(--transition-time);
    transform: translate(0px);
}

.logotypeClosed, .logotypeOpen {
    overflow: hidden;
    align-self: flex-end;
}

.logotypeClosed svg {
    align-self: flex-end;
    transition: var(--transition-time);
    transform: translateX(108px);
}

.logotypeOpen svg {
    align-self: flex-end;
    transition: var(--transition-time);
    transform: translateX(0px);
}

.logo {
    align-self: flex-start;
}

.icon path {
    transition: .3s;
    fill: #CBCBCB;
}

a:hover .icon path {
    fill: var(--primary-red);
}

.iconBackground {
    margin-right: 17px;
    padding: 11px;
    transition: var(--transition-time);
    border-radius: 7px;
}

.currentPageIconBackground {
    background-color: var(--hover);
}

.currentPageIconBackground .icon path {
    fill: var(--primary-red);
}

.listItem {
    padding-left: 17px;
    transition: .3s;
    color: var(--primary-black);
}

.listItem:hover {
    color: var(--primary-red);
    background-color: #FFF;
}

.listItem:hover > div > .iconBackground {
    background-color: var(--hover);
}

.languageButtons {
    position: absolute;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    align-self: center;
}

@media (max-width: 1224px) and (max-height: 520px )and (orientation: landscape) {
    .languageButtons {
        display: none;
    }
}

.menuIcon {
    width: 50px;
    height: 50px;
    fill: #CBCBCB;
    padding: 11px;
    cursor: pointer;
    border-radius: 7px;
}

.menuIcon:hover{
    fill: var(--primary-red);
    background-color: var(--hover);
    transition: 0.5s;
}