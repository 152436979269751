
.paginator {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.shape {
    min-width: 32px;
    height: 32px;
    display: flex;
    padding: 0 5px;
}

.border {
    border-radius: 3px;
    border: solid 1px #E2E2EA;
    background-color: #FFF;
    transition: .3s
}

.active:hover {
    border-radius: 3px;
    border: solid 1px var(--primary-red)
}

.icon path {
    fill: var(--primary-red);
    transition: .3s
}

.arrowBox {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin: auto;
    fill: #909090;
}

.number {
    font-family: var(--avenir-next-demi);
    font-size: var(--body-2-fs);
    color: #CBCBCB;
    margin: auto;
    cursor: pointer;
}

.selectedNumber {
    font-family: var(--avenir-next-demi);
    font-size: var(--body-2-fs);
    color: #CBCBCB;
    margin: auto;
    cursor: pointer;
}

.number:hover {
    color: var(--primary-red);
    transition: .3s
}

.show {
    font-family: var(--avenir-next-demi);
    font-size: var(--body-2-fs);
    color: #B4B2B2;
    margin: auto;
    padding-right: 0.1rem;
}

.selectItem {
    font-family: var(--avenir-next-demi);
    font-size: var(--body-2-fs);
    color: var(--primary-black);
    margin: auto;
}

.ellipsis {
    font-family: var(--avenir-next-demi);
    font-size: var(--body-2-fs);
    color: #CBCBCB;
    margin: auto;
}

.selectRowsNumberContainer {
    display: flex;
    padding-left: 1rem;
}
