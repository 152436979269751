.nameContainer {
    display: flex;
    font-size: var(--body-1-fs);
    line-height: 50px;
    text-align: left;
    padding-left: 12px;
    white-space: nowrap;
}

.name {
    text-overflow: ellipsis;
    overflow: hidden;
}

.fileChipsContainer {
    display: flex;
    background-color: var(--background);
    text-align: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.button:hover {
    background-color: transparent;
}
