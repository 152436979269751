.editUserAccountContainer {
    padding-left: 17px;
}

@media (max-width: 400px) {

    .editUserAccountContainer {
        padding-left: 0;
    }
}
