
.iconContainer {
    height: 70px;
    width: 106px;
    margin: 34px auto;
    position: relative;
}

.contentContainer {
    text-align: center;
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-2);
    color: var(--primary-black);
}

.checkIcon {
    position: absolute;
    z-index: 10;
}

.checkIconShadow path, .checkIconShadowTranslated path {
    fill: #c2edc9
}

.checkIconShadow {
    position: absolute;
    z-index: 0;
}

.checkIconShadowTranslated {
    position: absolute;
    z-index: 0;
    transform: translate(25%, 7%);
    transition: .7s;
    transition-delay: .1s;
}

.dialogActions {
    margin: 50px auto;
    flex-wrap: wrap;
    justify-content: center;
}

.formAlignedCenter {
    text-align: center;
}

.timeField {
    width: 112px
}

.cancellationCauseField {
    width: 338px
}
