.cargoContainer {
    padding: 23px;
    display: flex;
    flex-direction: column;
}

.cargoContainer h2 {
    margin-bottom: 20px;
}

.containerRow {
    display: flex;
    justify-content: space-between;
}

h2 {
    text-align: left;
}

.divider {
    height: 30px;
    background-color: var(--background);
    margin: 0 -23px 25px;
}