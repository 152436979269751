.dialogTitle {
    font-size: var(--heading-3);
}

.linkToRelated {
    font-size: var(--heading-3);
    line-height: 50px;
    display: block;
    cursor: pointer;
    text-decoration: underline;
    transition: .3s;
    color: var(--primary-black);
}

.linkToRelated:hover {
    color: var(--primary-red);
}


