.orderDetailsWrapper {
    min-width: 350px;
}

.header {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-2);
    line-height: 50px;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    text-align: left;
    background-color: var(--hover);
}

.divider {
    height: 25px;
    margin: 25px 0;
    background-color: var(--background);
}

.buttonMargin {
    margin-right: 20px;
}

.buttonsBox {
    display: flex;
    margin-top: 10px;
}

.detailsRow {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0;
    flex-wrap: wrap;
}

.titleRow {
    display: flex;
    align-items: center;
    flex-basis: 720px;
    flex-wrap: wrap;
}

.detailsColumn {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    padding-left: 20px;
    margin-top: 24px;
}

.detailsColumnPair {
    display: flex;
    flex-direction: row;
    z-index: 1;
    flex-grow: 1;
}

.secondInPair {
    width: 100%;
}

.orderIdBox {
    flex-wrap: wrap;
    padding: 20px;
    background-color: var(--background);
}

.detailsRow.orderIdBox {
    margin-top: 0;
}

.orderIdBox h1 {
    margin: 0;
    white-space: nowrap;
}

.orderDetailsTitle {
    line-height: 41px;
    height: 41px;
    margin-top: 10px;
    text-align: left;
}

.orderDetailsHeader {
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    padding-left: 20px;
}

.careProvidersBox {
    justify-content: flex-start;
    padding-left: 20px;
    padding-top: 24px;
}

.careProvidersBox > * {
    margin-right: 60px;
}

.attachments {
    justify-content: space-around;
    margin-top: 20px;
    padding: 0 20px;
    max-width: 630px;
}

.attachments ul {
    display: grid;
    padding: 0;
    list-style: none;
    column-gap: 25px;
    row-gap: 15px;
}

.emailBox {
    padding-top: 24px;
    padding-left: 24px;
    margin-bottom: -20px;
    background-color: var(--background);
}

.emailBox > div:first-child {
    padding-right: 15px;
}

.emailBox > div:nth-child(2) {
    padding-right: 15px;
}

.headerFiller {
    flex-grow: 100
}

@media (max-width: 1820px) {
    .detailsRow.clientsRow {
        flex-direction: column;
    }
}

@media (max-width: 1530px) {
    .attachments ul {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 1100px) {
    .orderIdBox {
        padding-top: 55px;
    }
}

@media (max-width: 910px) {
    .careProvidersBox {
        align-items: flex-start;
        flex-direction: column;
    }

    .careProvidersBox > * {
        margin-right: 0;
    }

    .detailsRow.emailBox {
        align-items: flex-start;
        flex-direction: column;
    }

    .emailBox > div:first-child {
        line-height: 50px;
        height: 50px;
    }

    .orderIdBox h1 {
        text-align: left;
    }
}


@media (max-width: 720px) {
    .detailsColumnPair {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .buttonsBox {
        flex-direction: column;
    }

    .buttonMargin {
        margin-bottom: 20px;
    }

    .orderDetailsHeader {
        flex-direction: column-reverse;
    }
}
