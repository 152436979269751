.background {
    padding: 15px 0;
    background-color: var(--background);
}

.contractorViewWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 997px;
    margin: 0 auto;
    background-color: #FFF;
}

.headerPhotoContainer {
    width: 100%;
    height: auto;
}

img {
    width: 100%;
    height: auto;
}

.contentContainer {
    width: 100%;
}

.footerContainer {
    display: flex;
    width: 100%;
    height: auto;
    padding: 8%;
    color: #FFF;
    flex-wrap: wrap;
    background-color: #060606;
    border-radius: 5px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: bottom;
    background-image: url('../../assets/images/group-4.jpg');
}

.reduceHeaderPadding {
    padding-left: 20px;
}

.header h1 {
    font-size: calc(12px + 1vw) !important;
    min-height: calc(50px + 3vw);
    overflow-wrap: break-word;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px 0
}

.subHeader {
    height: 50px;
    padding-left: 10%;
    text-align: left;
    background-color: var(--hover);
}

.subHeader > h3 {
    font-family: var(--avenir-next-demi);
    line-height: 50px;
}

.orderDetails {
    margin-left: 10%;
}

.orderDetailsLoadings {
    display: flex;
    flex-wrap: wrap;
}

.textWithIcon {
    margin: 15px;
    text-align: left;
}

.textWithIcon span {
    margin-left: 9px;
    font-family: var(--avenir-next-demi);
}

.carIcon {
    margin-right: 5px;
}

.attachments {
    flex-wrap: wrap;
    margin: 25px auto;
    padding: 0 20px;
    text-align: center;
}

.attachments ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    text-align: center;
}

.attachments ul li {
    min-width: 220px;
    width: 300px;
    margin: 0 15px 15px 0;
    padding: 0;
}

.questionsWrapper {
    flex: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.questionsTitle {
    text-align: left;
}

.questionsTitle p:first-child {
    font-family: var(--avenir-next-demi);
    font-size: 34px;
    margin-bottom: 0;
}

.questionsTitle p:last-child {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-2);
}

.orderCaretakersContainer {
    display: flex;
    justify-content: space-between;
    word-wrap: break-word;
    flex-wrap: wrap;
}

.reverseRow {
    flex-direction: row-reverse;
}

.caretaker {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: left;
    width: 100%;
    word-wrap: break-word;
    margin: 20px 10px 0 0;
    max-width: 250px;
}

.caretaker p:first-child {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-3);
    margin-bottom: 3px;
}

.caretaker p:nth-child(2) {
    font-family: var(--avenir-next-demi);
    font-size: var(--heading-2);
    margin-bottom: 2px;
}

.caretaker a {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-3);
    text-decoration: underline;
    color: #FFF;
}

.satisfied {
    align-self: center;
    flex: 1;
    margin-top: 20px;
}

.satisfied p {
    font-family: var(--avenir-next-demi);
    font-size: 24px;
}

.button {
    width: 224px;
}

.attachmentFilename {
    max-width: 200px;
}
