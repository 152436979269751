.dialogButton {
    padding: 6px;
}

.dialogActions {
    margin: 24px auto;
}

.downloadButton{
    height: 50px;
}

.contentContainer {
    text-align: center;
    height: 65vh;
}

.fileFormatIcon{
    height: 75px;
    width: 75px;
}
.image{
    max-width: 70vw !important;
    max-height: 55vh;
    width: auto;
}

.accessIcon {
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.denied {
    fill: lightgrey;
}

.accepted {
    fill: #21bb3a;
}

.exitIcon{
    height: 20px;
    width: 20px;
    margin-left: 25px;
    margin-right: 5px;
    cursor: pointer;
    fill: var(--primary-red);
}
.downloadIcon{
    height: 30px;
    width: 30px;
    margin-top: -9px;
    cursor: pointer;
    fill: var(--primary-red);
}
