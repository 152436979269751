.readIcon {
    width: 15px;
    height: 15px;
    fill: var(--secondary-gray);
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
}

.body {
    margin: 0 14px;
    white-space: normal;
}

.markAsReadContainer {
    text-align: end;
    line-height: 15px;
    margin-top: 10px;
}

.title {
    font-family: var(--avenir-next-demi);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.ellipse {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 13px;
}

.active {
    background-color: var(--primary-red);
}

.inactive {
    background-color: var(--secondary-gray);
}

.content {
    font-family: var(--avenir-next-medium);
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0;
    text-align: left;
    padding-right: 15px;
}

.footer {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerLeft {
    font-family: var(--avenir-next-demi);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
}

.footerRight {
    color: var(--secondary-gray);
    font-family: var(--avenir-next-medium);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0;
    text-align: right;
}