.actions {
    display: flex;
    flex-direction: column-reverse;
    margin: 30px 0 90px;
    padding-left: 15%;
    flex-wrap: wrap;
}

.rowWrapper {
    display: flex;
    position: relative;
}

.rowWrapperLastCompleted {
    display: flex;
    position: relative;
    padding: 24px 0;
    z-index: 1;
}

.bubbleDone {
    z-index: 10;
    align-self: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-color: var(--primary-red);
}

.bubbleToDo {
    z-index: 10;
    align-self: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-color: var(--hover);
}

.bubbleLastCompleted {
    align-self: center;
    width: 62px;
    height: 62px;
    min-width: 62px;
    min-height: 62px;
    margin: 0 20px 0 -20px;
    border: 10px solid #FFF;
    border-radius: 50%;
    background-color: var(--primary-red);
    box-shadow: 0 0 0 1px var(--primary-red);
}

.statusBar {
    display: flex;
    flex-direction: column;
}

.joiningLine {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 11px;
    width: 2px;
    height: 100%;
    min-width: 2px;
    min-height: 24px;
    background-color: var(--primary-red);
}

.arrowUp {
    background-color: #FFF;
    border-radius: 50%;
    z-index: 6;
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    margin: 1px;
}

.completedArrowUp {
    background-color: #FFF;
    border-radius: 50%;
    z-index: 6;
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
    margin: -1px;
}

.bubbleLastCompleted > div:nth-child(1) {
    height: 50%;
    background-color: var(--hover);
    z-index: -1;
}

.bubbleLastCompleted > div:nth-child(2) {
    top: 50%;
    height: 50%;
    z-index: -1;
}

.actions > .rowWrapperLastCompleted:last-child > .bubbleLastCompleted > div:nth-child(1) {
    display: none;
}

.bubbleToDo > .joiningLine {
    background-color: var(--hover);
}

.actions .rowWrapper:first-child div[class*='bubble'] .joiningLine {
    height: 50%;
}

.actions .rowWrapper:last-child div[class*='bubble'] .joiningLine {
    transform: translate(0%, 50%);
}

.action {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-2);
    line-height: 48px;
    display: flex;
    align-self: center;
    justify-content: flex-start;
    padding-left: 21px;
    flex-wrap: wrap;
}

.bubbleDone + .action,
.bubbleToDo + .action {
    padding-left: 60px;
    flex-wrap: wrap;
    height: 100%;
}

.bubbleLastCompleted + .action > .actionTypeCell,
.bubbleLastCompleted + .action > .actionCell {
    font-family: var(--avenir-next-demi);
    font-size: 22px;
}

.bubbleLastCompleted + .action > .actionCell:first-child {
    margin-right: 16px;
}

.bubbleLastCompleted + .action > .actionCell:nth-child(2) {
    margin-right: 24px;
}

.bubbleToDo + .action > .actionTypeCell,
.bubbleToDo + .action > .actionCell {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-2);
    color: #CBCBCB;
}

.actionCell {
    margin-right: 30px;
}

.actionTypeCell {
    text-align: left;
}

.inactiveIcon path {
    fill: var(--hover);
}

