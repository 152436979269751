.button {
    font-family: var(--avenir-next-demi);
    background-color: var(--primary-red);
    color: #FFF ;
    border: 1px solid var(--primary-red);
    border-radius: 3px;
    height: 43px;
    font-size: var(--button-fs);
    transition: .3s;
    white-space: nowrap;
}

.button:hover {
    background-color: #bc1c24;
    border-color: #bc1c24;
    cursor: pointer;
}

button:focus {
    outline: none !important;
}

.buttonIcon {
    vertical-align: 2px;
    margin-right: 7px;
}

.buttonIcon svg path {
    fill: #FFF;
    stroke: #FFF
}

.defaultStyles {
    width: 310px
}

.withIcon {
    padding-right: 13px;
    line-height: inherit
}

.withoutIcon {
    padding-right: 6px;
    line-height: 43px
}