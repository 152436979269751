.titleContainer {
    display: flex;
    width: 100%;
}

.indexBox {
    height: 41px;
    width: 43px;
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-3);
    line-height: 41px;
    border-radius: 3px;
    background-color: var(--background);
}

.titleBox {
    flex: 1;
    text-align: left;
    margin-left: 6px;
    padding-left: 15px;
    height: 41px;
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-3);
    line-height: 41px;
    border-radius: 3px;
    background-color: var(--background);
}

.rowInColumn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.row {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.col {
    display: flex;
    flex-direction: column;
    width: 310px;
    margin-right: 60px;
}

.halfSizeTextField {
    width: 150px;
}

@media (max-width: 1400px) {
    .col {
        margin-top: 20px;
    }
}