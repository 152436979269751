.notificationsWrapper {
    width: 255px;
    overflow-y: auto;
    height: calc(100vh - 200px);
    overflow-x: hidden;
}

.infiniteScroll {
    overflow: hidden !important;
}

.notification {
    border-bottom: 1px solid var(--border-input);
}

.notRead {
    background-color: var(--hover);
}

.noNotificationsContainer {
    color: #909090;
    padding: 1.5rem 0 0 1rem;
}

.markAllReadButton {
    color: var(--primary-red);
    background-color: var(--extra-background-gray);
    white-space: normal;
    padding: 10px 24px;
    font-family: var(--avenir-next-demi);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    text-transform: none;
}

.check {
    fill: var(--primary-red);
    width: 15px;
    height: 15px;
}

.secondCheck {
    margin-left: -5px;
    margin-right: 5px;
}
