.col {
    display: flex;
    flex-direction: column;
    width: 310px;
    margin-top: 5px;
}

.container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.title {
    display: flex;
    align-items: baseline;
}

.warningIcon {
    height: 22px;
    margin-left: 8px;
}

@media (max-width: 1400px) {
    .col {
        margin-top: 20px;
    }
}

@media (max-width: 1224px) {
    .title {
        justify-content: center;
    }
}

@media (max-width: 1160px) {
    .order3 {
        order: 3;
    }

    .order1 {
        order: 1
    }
}