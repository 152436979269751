.container{
  display: flex;
  flex-direction: column;
}

.block{
  display: block;
}

.usernameFieldContainer.disableHelperTextMargin > div > p {
  margin-top: 0;
}