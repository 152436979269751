.titleContainer > h2 {
    text-align: center;
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-2);
    color: var(--primary-black);
}

.contentContainer {
    text-align: center;
    font-family: var(--avenir-next-medium);
    font-size: var(--body-1-fs);
    color: var(--caption);
}

.dialogButton {
    margin: 5px;
    width: 224px;
    justify-content: center;
    display: flex;
}

.dialogActions {
    margin: 10px auto;
    flex-wrap: wrap;
}


