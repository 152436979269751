.mailContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: -23px;
    padding: 0 23px 0;
    background-color: var(--background);
    flex-wrap: wrap;
}

.mailFormContainer {
    display: flex;
    margin-top: 25px;
}

.mailFormContainer > div {
    margin-right: 20px;
}

.mailFormContainer > div:first-child {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-3);
    margin-top: 16px;
}

@media (max-width: 580px) {
    .mailContainer {
        padding-bottom: 24px;
    }

    .mailFormContainer {
        flex-direction: column;
        align-items: flex-start;
    }

    .mailFormContainer > div:first-child {
        margin-top: 0;
        margin-bottom: 16px;
    }
}

.intervalField {
    width: 245px;
}

.languageField {
    width: 125px;
}