.title {
    padding-left: 30px;
}

.title h1 {
    margin-bottom: 0;
}

.titleBarBackground {
    padding: 25px 0 15px 0;
    background-color: var(--extra-background-gray);
}

.buttonMargin {
    margin: 0 10px 10px 25px;
}

.header, .headerTwoColumn {
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-2);
    height: 50px;
    padding: 10px 0 10px 34px;
    text-align: left;
    white-space: nowrap;
    background-color: var(--hover);
}

.detailsColumn {
    display: flex;
    flex-direction: column;
}

.detailsContentColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 310px;
    padding-left: 34px;
    text-align: left;
    margin-top: 24px;
}

.flexHeader, .flexHeaderTwoColumn {
    flex-grow: 2 !important;
    height: 50px;
    background-color: var(--hover);
}

.contentRow, .contentRowTwoColumn {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
}

@media (max-width: 1224px) {
    .contentRow.titleRow {
        flex-direction: column;
        margin-top: 20px;
    }

    .buttonMargin {
        margin: 20px 10px;
    }

    .title {
        padding-left: 0;
    }
}

@media (max-width: 1160px) {
    .contentRow {
        flex-direction: column;
    }

    .flexHeader {
        display: none;
    }

    .header {
        margin-top: 25px;
    }

    .detailsColumn:first-child > .header {
        margin-top: 0;
    }
}

@media (max-width: 730px) {
    .contentRowTwoColumn {
        flex-direction: column;
    }

    .flexHeaderTwoColumn {
        display: none;
    }

    .headerTwoColumn {
        margin-top: 25px;
    }

    .detailsColumn:first-child > .headerTwoColumn {
        margin-top: 0;
    }
}

@media (max-width: 400px) {
    .detailsContentColumn {
        align-items: center;
        padding-left: 0;
    }
}
