:root {
    /*Primary colors*/
    --primary-red: #ed1c24;
    --primary-black: #000;
    /*Secondary colors*/
    --caption: #44444f;
    --secondary-gray: #CBCBCB;
    --border-input: #E2E2EA;
    --background: #f5f5f5;
    --hover: #fff2f2;
    /*Extra colors*/
    --validation: #EB9200;
    --validation-background: #fff4e2;
    --selected-input: #00029a;
    --confirmation: #21bb3a;
    --extra: #d5ffdc;
    --fill-input: #eaeaea;
    --extra-background-gray: #fafafb
}
