.historyTabsWrapper {
    display: flex;
    flex-direction: column;
}

.contentContainer {
    margin-top: 1.5rem;
    text-align: center;
    font-family: var(--avenir-next-medium);
    font-size: var(--heading-2);
    color: var(--primary-black);
}

.orderDetails {
    cursor: pointer;
    color: var(--primary-red);
}
